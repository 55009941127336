import * as React from 'react';
import {Helmet} from 'react-helmet';
import Layout from '../components/layout';
import {useOliverDemoPage} from '../hooks/queries';
import '../styles/pages/oliver-demo.scss';

const OliverDemo = () => {
  const {title, videoUrl} = useOliverDemoPage();
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      <div className="oliver-demo m-auto w-full flex flex-col justify-center items-center py-12">
        <iframe
          className="oliver-demo__video"
          src={videoUrl}
          title={videoUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </Layout>
  );
};

export default OliverDemo;
